<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<!-- BCT: Added by BCT -->
<template>
  <div class="section-content pt-0 pr-0">
    <v-divider></v-divider>
    <HbPropertySelector
      id="property"
      :items="facilityList"
      item-text="name"
      item-value="id"
      v-validate="'required'"
      v-model.lazy="property_id"
      data-vv-scope="gate"
      data-vv-name="property"
      data-vv-as="Property"
      :error="errors.has('gate.property')"
    />

    <div class="mr-6">
      <hb-empty-state 
        v-if="!property_id"
        message="Please select a Property to continue."
        :showBtn="false"
      >
      </hb-empty-state>
      <div v-else>
        <div class="main-section-heading pa-0 mr-0 mb-4">
          <v-row class="w-100 mx-0">
            <v-col cols="11" class="ma-0 pa-0">
              <hb-tabs
                v-model="activeTab"
              >
                <v-tab
                  v-for="(item, index) in getUnitTypes('have_feature_amenities')"
                  :key="index"
                  >{{ item.display_name }}</v-tab
                >
              </hb-tabs>
            </v-col>
          </v-row>
        </div>
        <v-tabs-items v-model="activeTab" class="features-amenities-tab-items">
          <v-tab-item v-for="(item, index) in getUnitTypes('have_feature_amenities')" :key="index" class="tab-item">
            <features-and-amenities-list
              :activeTab="item"
              :property_id="property_id"
              @addFeaturesAndAmenitiesEvent="onClickAddFeaturesAndAmenities"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <!--// Add Features and Amenities Popup -->
    <add-features-and-amenities
      v-if="showAddDialog"
      :property_id="property_id"
      :activeTab="getUnitTypes('have_feature_amenities')[activeTab]"
      v-model="showAddDialog"
      @close="showAddDialog = false"
    ></add-features-and-amenities>
    <!-- Add Features and Amenities Popup //-->

    <!-- Create Custom Feature and Amenity Popup -->
    <custom-feature-and-amenity
      :property_id="property_id"
      :activeTab="getUnitTypes('have_feature_amenities')[activeTab]"
      v-model="showCustomAmenityDialog"
    ></custom-feature-and-amenity>

  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "../../../EventBus.js";
import FeaturesAndAmenitiesList from "./FeaturesAndAmenitiesList.vue";
import AddFeaturesAndAmenities from "./AddFeaturesAndAmenities/AddFeaturesAndAmenities.vue";
import CustomFeatureAndAmenity from './CustomFeatureAndAmenity.vue';
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: "FeatureAndAmenityIndex",
  mixins: [ notificationMixin ],
  data() {
    return {
      showDeleteDialog: false,
      showCustomAmenityDialog: false,
      showAddDialog: false,
      featureList: [],
      property_id: "",
      level: "Property",
      activeTab: 0
    };
  },
  props: {
    facilityID: {
      type: String,
      default: ""
    }
  },
  components: {
    FeaturesAndAmenitiesList,
    AddFeaturesAndAmenities,
    CustomFeatureAndAmenity,
    EmptyStateContainer
  },
  async created() {
    EventBus.$on('update_property_amenities', this.handleEvent);
    EventBus.$on('custom_amenity_creation', this.handleEvent);
    EventBus.$on('property_amenities_deletion', this.handleEvent);
  },
  destroyed() {
    EventBus.$off('update_property_amenities');
    EventBus.$off('custom_amenity_creation');
    EventBus.$off('property_amenities_deletion');
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered"
    })
  },
  methods: {
    ...mapActions({
      fetchFeatures: "featureAndAmenitiesStore/fetchFeatures",
      fetchAllFeatures: "featureAndAmenitiesStore/fetchAllFeatures",
      removeGlobalNotificationByType: "globalNotificationsStore/removeGlobalNotificationByType"
    }),
    getFeatures() {
      if (this.property_id) {
        this.fetchFeatures({
          property_id: this.property_id,
          unit_type_id: this.getUnitTypes('have_feature_amenities')[this.activeTab].unit_type_id
        });
      }
    },
    onClickAddFeaturesAndAmenities() {
      this.fetchAllFeatures({
        property_id: this.property_id,
        unit_type_id: this.getUnitTypes('have_feature_amenities')[this.activeTab].unit_type_id
      });
      this.showAddDialog = true;
    },
    handleEvent(data) {
      this.removeGlobalNotificationByType({ type: 'property_amenities' })
      switch(data.state) {
        case "Ready":
          this.showMessageNotification({title: 'Process Started', type: 'property_amenities', loading: true, description: data.message});
          break;
        case "Error":
          this.showMessageNotification({type: 'error', description: data.message});
          break;
        case "Done":
          this.showMessageNotification({type: 'success', description: data.message});
          this.getFeatures();
          EventBus.$emit('unit_edited');
          break;
      }
    }
  },
  watch: {
    property_id() {
      this.getFeatures();
    },
    activeTab() {
      this.getFeatures();
    }
  }
};
</script>

<style scoped>
.tab-bar {
  color: #101318;
  text-transform: none;
  background: #f9fafb;
}
.tab-item {
  background: #f9fafb;
  height: 100%;
  width: 100%;
}
.features-amenities-tab-items {
  background: #f9fafb;
  overflow: visible;
}
</style>
